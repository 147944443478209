import React from "react"
import { Container, Header, List, Icon, Breadcrumb } from "semantic-ui-react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import logo from "../images/progmiscon-logo-64.png"
import SelfCheckButton from "../components/SelfCheckButton"

// pageContext comes from createPage, data comes from the below GraphQL page query
const TextbookSectionPageTemplate = ({ pageContext, data }) => {
  //const { glob } = pageContext;
  const { textbookId, sectionId } = pageContext
  const { nodes } = data.allMdx
  const node = nodes[0]
  const section = node.frontmatter.sections.filter(
    sec => sec.section === sectionId
  )[0]
  const mcNodes = data.mcs.nodes
  // We need to filter in JavaScript, 
  // because Gatsby/Sift's elemMatch GraphQL query feature seems broken
  // (see comment below the GraphQL query at the end of this file)
  const filteredMcNodes = mcNodes.filter(
    mcNode => mcNode.frontmatter.text.filter(
      tb => tb.id===textbookId && tb.sections.filter(s => s===sectionId).length > 0
    ).length > 0
  )
  return (
    <Layout>
      <SEO
        title={`${section.section} ${section.title} - ${node.parent.name}`}
      />
      <Container text style={{ marginTop: "100px", marginBottom: "100px" }}>
        <Breadcrumb>
          <Breadcrumb.Section link as={Link} to="/">
            Home
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right chevron" />
          <Breadcrumb.Section link as={Link} to="/textbooks">
            Textbooks
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right chevron" />
          <Breadcrumb.Section>{node.frontmatter.pl}</Breadcrumb.Section>
          <Breadcrumb.Divider icon="right chevron" />
          <Breadcrumb.Section
            link
            as={Link}
            to={`/textbooks/${node.parent.name}`}
          >
            {node.parent.name}
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right chevron" />
          <Breadcrumb.Section active>{section.title}</Breadcrumb.Section>
        </Breadcrumb>

        <Header as="h1" dividing>
          <Icon name="book" />
          <Header.Content>
            <Header.Subheader>
              {node.frontmatter.title}: {node.frontmatter.subtitle},{" "}
              {node.frontmatter.edition}
            </Header.Subheader>
            {section.section}. {section.title}
          </Header.Content>
        </Header>

        {node.frontmatter.baseUrl ? (
          <p>
            <a
              href={`${node.frontmatter.baseUrl}${section.url}`}
            >{`${node.frontmatter.baseUrl}${section.url}`}</a>
          </p>
        ) : null}

        <p>{filteredMcNodes.length} Documented Misconceptions</p>

        {
          filteredMcNodes.length>0 ? (
            <SelfCheckButton primary textbook={node.parent.name+"/"+section.section} />
          ) : null
        }

        <List selection verticalAlign="middle">
          {filteredMcNodes.map((mcNode) => (
            <List.Item as={Link} to={mcNode.fields.slug} key={mcNode.fields.slug}>
              <img
                className="ui image"
                alt="Misconception"
                width="20"
                src={logo}
              />
              <List.Content>
                <List.Header>
                  {/* color this like a link, without producing a nested a element */}
                  <span style={{ color: "#4183c4" }}>{mcNode.fields.name}</span>
                </List.Header>
                {mcNode.frontmatter.shortDescription}
              </List.Content>
            </List.Item>
          ))}
        </List>
      </Container>
    </Layout>
  )
}

export default TextbookSectionPageTemplate

export const query = graphql`
  query($glob: String!, $textbookId: String!, $sectionId: String!) {
    allMdx(filter: { fileAbsolutePath: { glob: $glob } }) {
      nodes {
        frontmatter {
          title
          subtitle
          edition
          pl
          home
          baseUrl
          sections {
            section
            title
            url
          }
        }
        parent {
          ... on File {
            name
          }
        }
      }
    }
    mcs: allMdx(
      filter: {
        frontmatter: {
          text: {
            elemMatch: { id: { eq: $textbookId }, sections: { eq: $sectionId } }
          }
        }
      }
    ) {
      nodes {
        fields {
          slug
          name
        }
        frontmatter {
          shortDescription
          text {
            id
            sections
          }
        }
      }
    }
  }
`

// In the above query, elemMatch is not working as expected.
// It is supposed to ONLY match mcs that have a `text` frontmatter entry
// that contains an element with BOTH the expected textbookId AND the expected sectionId.
// However, it seems to return ALL mcs that have a `text` frontmatter entry
// that contains an element with the expected textbookId OR the expected sectionId.
// I suspect this is a bug in the `sift.js` library:
// https://github.com/crcn/sift.js#elemmatch
// https://github.com/crcn/sift.js/blob/e2422753ce2fcf4a3bcc8183a8ed7411b7fb5df4/src/operations.ts#L50
// Gatsby uses this library to implement the GraphQL queries.
// That library implements a subset of the MongoDB query language.
// https://docs.mongodb.com/manual/reference/operator/query/elemMatch/
// It does not implement the full subset, so it's impossible to work around this problem
// within the GraphQL query, but we have to further filter the results in JavaScript.
